<script setup lang="ts">
import type { Swiper as SwiperType } from 'swiper'
import dayjs from 'dayjs'
import { Swiper, SwiperSlide } from 'swiper/vue'

defineSlice({
  name: { label: 'Bento Calendar Illustration', group: 'Bento', layouts: ['VoixMarketing'] },
  description: '',
  preview: 'SlicesBentoCalendarAnimation.jpg',
  fields: {

  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const activated = inject('activated')

const swiper: Ref<SwiperType | null> = ref(null)
const animation = ref<NodeJS.Timeout | null>(null)
function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
  animation.value = setInterval(() => {
    if (activated.value) {
      if (swiperInstance.realIndex === 11)
        swiperInstance.slideTo(0)
      else
        swiperInstance.slideNext()
    }
  }, 1500)
}

function calculateMonth(monthNumber: number) {
  return dayjs().month(monthNumber).format('MMMM')
}

const currentSlideIndex = computed(() => {
  if (swiper.value?.realIndex)
    return swiper.value?.realIndex + 1

  return 0
})

function isOff(index: number) {
  return !activated.value || (index !== 1 && currentSlideIndex.value !== index) || (index === 1 && currentSlideIndex.value !== 0)
}
</script>

<template>
  <div class="absolute inset-0 overflow-hidden flex justify-center items-center py-12  -translate-y-[10%]">
    <Swiper
      slides-per-view="auto"
      :centered-slides="true"
      :center-insufficient-slides="true"
      space-between="16"
      class="w-full h-110"
      @swiper="onSwiper"
    >
      <SwiperSlide v-for="n in 12" :key="n" class="relative">
        <svg class="absolute z-0 inset-0 w-full h-full duration-1000 blur-2xl" :class="{ 'opacity-0 ': isOff(n), 'opacity-50': !isOff(n) }">
          <defs>
            <linearGradient id="a" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1" y2="1">
              <stop offset="0" stop-color="red">
                <animate
                  attributeName="stop-color"
                  values="red;purple;blue;green;yellow;orange;red;" dur="10s" repeatCount="indefinite"
                />
              </stop>
              <stop offset=".5" stop-color="purple">
                <animate
                  attributeName="stop-color"
                  values="purple;blue;green;yellow;orange;red;purple;" dur="10s" repeatCount="indefinite"
                />
              </stop>
              <stop offset="1" stop-color="blue">
                <animate
                  attributeName="stop-color"
                  values="blue;green;yellow;orange;red;purple;blue;" dur="10s" repeatCount="indefinite"
                />
              </stop>
              <animateTransform
                attributeName="gradientTransform" type="rotate" from="0 .5 .5" to="360 .5 .5"
                dur="10s" repeatCount="indefinite"
              />
            </linearGradient>
            <linearGradient id="b" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="1" y2="1">
              <stop offset="0" stop-color="red">
                <animate
                  attributeName="stop-color"
                  values="red;purple;blue;green;yellow;orange;red;" dur="10s" repeatCount="indefinite"
                />
              </stop>
              <stop offset="1" stop-color="purple" stop-opacity="0">
                <animate
                  attributeName="stop-color"
                  values="purple;blue;green;yellow;orange;red;purple;" dur="10s" repeatCount="indefinite"
                />
              </stop>
              <animateTransform
                attributeName="gradientTransform" type="rotate" values="360 .5 .5;0 .5 .5" class="ignore"
                dur="10s" repeatCount="indefinite"
              />
            </linearGradient>

          </defs>

          <g>
            <g>
              <rect fill="url(#a)" width="100%" height="100%" />
              <rect fill="url(#b)" width="100%" height="100%" />
            </g>
          </g>
        </svg>

        <div class="relative z-10 rounded p-4 duration-500 " :class="{ 'bg-neutral-900 opacity-40 ': isOff(n), 'bg-white scale-110': !isOff(n) }">
          <div>
            <div class="text-center text-2xs uppercase font-light text-neutral-500 pb-2">
              {{ calculateMonth(n - 1) }}
            </div>
            <div class="h-36 w-36">
              <svg class="h-full w-full object-contain" viewBox="0 0 501 501">
                <!-- Define the grid dimensions -->
                <rect x="0" y="0" width="500" height="500" fill="none" stroke="#404040" />
                <!-- Horizontal lines -->
                <line x1="0" y1="83.33" x2="500" y2="83.33" stroke="#404040" />
                <line x1="0" y1="166.66" x2="500" y2="166.66" stroke="#404040" />
                <line x1="0" y1="249.99" x2="500" y2="249.99" stroke="#404040" />
                <line x1="0" y1="333.32" x2="500" y2="333.32" stroke="#404040" />
                <line x1="0" y1="416.65" x2="500" y2="416.65" stroke="#404040" />
                <line x1="0" y1="499.98" x2="500" y2="499.98" stroke="#404040" />
                <!-- Vertical lines -->
                <line x1="71.42" y1="0" x2="71.42" y2="500" stroke="#404040" />
                <line x1="142.84" y1="0" x2="142.84" y2="500" stroke="#404040" />
                <line x1="214.26" y1="0" x2="214.26" y2="500" stroke="#404040" />
                <line x1="285.68" y1="0" x2="285.68" y2="500" stroke="#404040" />
                <line x1="357.1" y1="0" x2="357.1" y2="500" stroke="#404040" />
                <line x1="428.52" y1="0" x2="428.52" y2="500" stroke="#404040" />
                <line x1="499.94" y1="0" x2="499.94" y2="500" stroke="#404040" />
              </svg>
            </div>

            <div class="absolute inset-0 flex justify-center items-center">
              <Icon name="heroicons:check-circle" class="block w-8 h-8 text-voix-alt1 ease-out duration-500 transition-all scale-50 delay-200" :class="{ 'scale-125 opacity-0 ': isOff(n), 'scale-[200%]': !isOff(n) }" />
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>

<style>
.swiper-wrapper {
  @apply flex;
}

.swiper-slide {
  @apply px-8;
}
</style>
